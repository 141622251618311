import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
// import { setEqualHeight } from "utils";
// import { addGermanDecimal } from "utils";
import { useFormattedMessage } from "hooks";

import {
    PROJECT_TYPE,
    SECONDARY_MARKET_PROJECT_STATUS,
    getProjectTypeTranslatedLabels,
    clubDealCavalryProjectIDs,
} from "constants/index";
import { getProjectInvestmentStatusLookUp } from "utils/statusLookUp";

import { PROJECT_STATUS } from "constants/status";

import { selectLocale } from "store/app/selectors";

import CustomFormatNumber from "components/CustomFormatNumber";
import PropertyItemProto from "components/PropertyItemProto";
import StatusRibbon from "components/PropertyItemProto/components/StatusRibbon";
import MidRow from "components/PropertyItemProto/components/MidRow";
import FooterDiv from "components/PropertyItemProto/components/FooterDiv";
import FooterPropertyRow from "components/PropertyItemProto/components/FooterPropertyRow";
import EqualProfitContainer from "components/PropertyItemProto/components/EqualProfitContainer";
import IntlFormattedMessage from "./IntlFormattedMessage";

const messages = {
    pricePerShare: "property_price_per_share",
    numberDigitalParts: "number_of_digital_shares",
    financingVolume: "financing_volume",
    totalValue: "property_total_value",
    indicativeWealth: "property_indicative_wealth",
    predictedTotalReturn: "property_predicted_total_return",
    numberOfInvestments: "number_of_investments",
    tokenPrice: "assets_token_price",
    assetfooterNote: "property_asset_footer_note",
    realClubfooterNote: "property_real_club_footer_note",
    privateEquityFooterNote: "property_private_equity_footernote",
    spread: "property_spread",
    marketPriceVolatility: "property_market_price_volatility",
    tokenMarketPrice: "property_token_marketprice",
    indicativeTokenValue: "property_indicative_token_value",
    performance: "meta_title_performance_page",
    propertyTotalReturn: "property_total_return",
    propertyPredictedTotalReturn: "property_predicted_totalreturn",
    propertyDuration: "property_duration",
    propertyRealizedTotalReturn: "property_realized_total_return",
};

const PercentValueCom = ({ percentValue, right }) => (
    <>
        {typeof percentValue !== "undefined" ? (
            <div className="d-flex align-end">
                <span className={`text-12 fw-600 mr-2 ${Math.sign(percentValue) === -1 ? "danger-red-color" : "primary-color"}`}>
                    {`${Math.sign(percentValue) === 1 ? "+" : ""}`}
                    <CustomFormatNumber value={percentValue} symbol="%" />
                </span>
                <CustomFormatNumber value={right} />
            </div>
        ) : (
            <CustomFormatNumber value={right} />
        )}
    </>
);

const TokenPriceValueCom = ({ performancePrice }) => {
    const { formatMessage } = useFormattedMessage();
    return (
        <>
            {
                <div className={`flex justify-between performance-tile ${Math.sign(performancePrice) === -1 ? "down" : "up"}`}>
                    <span className="text-12 fw-600">{formatMessage(messages.performance)}</span>
                    <div className="d-flex align-end">
                        <span
                            className={`text-12 fw-600 ${
                                Math.sign(performancePrice) === -1 ? "danger-red-color" : "primary-dark-color"
                            }`}
                        >
                            {`${Math.sign(performancePrice) === 1 ? "+ " : ""}`}
                            <CustomFormatNumber value={performancePrice} symbol="€" />
                        </span>
                    </div>
                </div>
            }
        </>
    );
};

const NoValue = ({ locale, symbol = "%" }) => {
    return locale == "de" ? <>-,-- {symbol}</> : <>-.-- {symbol}</>;
};

const PropertyItem = ({
    image,
    propertyType,
    location,
    title,
    profit,
    returnEstimate,
    price,
    shares,
    digitalShares,
    digitalSharesSymbol,
    aggregate,
    indicativeWealth,
    fundingPercentage,
    status,
    onClick,
    VIPUser,
    className,
    tileHoverAnimation,
    projectID,
    numberOfInvestments = 0,
    isAssets = false,
    percentValue,
    tokenPrice,
    valuationDate,
    performancePrice,
    buyCheapestPrice,
    sellCheapestPrice,
    sellHighestPrice,
    locale,
    monthlyTMPVolatility,
    TMP,
    ITV,
    reference,
    isVideo,
    videoPath,
    isNotLink,
    href,
    actionBox,
}) => {
    const PROJECT_TYPE_TRANSLATED_LABELS = getProjectTypeTranslatedLabels();

    const { formatMessage } = useFormattedMessage();

    const newStatus = VIPUser && status === PROJECT_STATUS.VIP_FUNDING ? PROJECT_STATUS.FUNDING_ACTIVE : status;

    const footerNote = {
        [PROJECT_TYPE.FINE_WINE]: <IntlFormattedMessage id={messages.assetfooterNote} />,

        [PROJECT_TYPE.ART]: <IntlFormattedMessage id={messages.assetfooterNote} />,
        [PROJECT_TYPE.CLASSIC_CARS]: <IntlFormattedMessage id={messages.assetfooterNote} />,
        [PROJECT_TYPE.DIAMOND]: <IntlFormattedMessage id={messages.assetfooterNote} />,
        [PROJECT_TYPE.WATCHES]: <IntlFormattedMessage id={messages.assetfooterNote} />,
        [PROJECT_TYPE.PRIVATE_EQUITY]: formatMessage(messages.privateEquityFooterNote),
        [PROJECT_TYPE.REAL_ESTATE]: <IntlFormattedMessage id={messages.realClubfooterNote} />,
        [PROJECT_TYPE.CL_PUBLIC_PLACEMENT]: <IntlFormattedMessage id={messages.realClubfooterNote} />,
        [PROJECT_TYPE.CL_DIRECT_SELECT]: <IntlFormattedMessage id={messages.realClubfooterNote} />,
    };

    const renderFooterNote = (type) => {
        return footerNote[type] || <IntlFormattedMessage id={messages.predictedTotalReturn} />;
    };

    // const spread = (sellCheapestPrice && buyCheapestPrice) && (((sellCheapestPrice - buyCheapestPrice) / sellCheapestPrice) * 100); //old formula
    const spread = sellCheapestPrice && buyCheapestPrice && ((buyCheapestPrice - sellCheapestPrice) / buyCheapestPrice) * 100;
    const isPrivateEquityProduct = propertyType === PROJECT_TYPE.PRIVATE_EQUITY;
    const privateEquityActionItem = isPrivateEquityProduct
        ? actionBox?.line?.find((item) => item.left == "Progn. Zielfonds-Multiple")
        : "";
    const privateEquityReturn = privateEquityActionItem?.right?.replace(/>| |x/gi, "")?.replace(",", ".");

    const isCavalryProduct = isPrivateEquityProduct || clubDealCavalryProjectIDs.includes(projectID);
    const investmentStatusLookup = getProjectInvestmentStatusLookUp(newStatus);
    return (
        <PropertyItemProto
            isNotLink={isNotLink}
            href={href}
            tileHoverAnimation={tileHoverAnimation}
            image={image}
            imageText={PROJECT_TYPE_TRANSLATED_LABELS[propertyType] || propertyType}
            onClick={onClick}
            className={className}
            dataType={propertyType}
            dataStatus={status}
            reference={reference}
            isVideo={isVideo}
            videoPath={videoPath}
        >
            {newStatus && (
                <StatusRibbon
                    {...investmentStatusLookup}
                    projectID={projectID}
                    percentage={
                        newStatus === PROJECT_STATUS.FUNDING_CLOSED || newStatus === PROJECT_STATUS.FINANCING_COMPLETED
                            ? 100
                            : fundingPercentage
                    }
                    buyCheapestPrice={buyCheapestPrice}
                    sellHighestPrice={sellHighestPrice}
                    isProgressBar={!isCavalryProduct && investmentStatusLookup?.isProgressBar}
                />
            )}
            {isAssets && <TokenPriceValueCom performancePrice={performancePrice} />}
            <EqualProfitContainer
                makeContainerEqualHeight={false}
                equalSection={
                    <>
                        {location ? (
                            <span className="text-12 fw-500 property-item-text-overflow my-1" title={location}>
                                {location}
                            </span>
                        ) : null}
                        {title ? (
                            <h6 className="property-item-text-overflow" title={title}>
                                {title}
                            </h6>
                        ) : null}
                    </>
                }
                profit={
                    newStatus === PROJECT_STATUS.SUCCESSFULLY_PAID_BACK ? (
                        <>
                            <MidRow
                                left={formatMessage(messages.propertyTotalReturn)}
                                right={
                                    <CustomFormatNumber
                                        value={
                                            actionBox?.line[1]?.right?.split(",")[0] +
                                            "." +
                                            actionBox?.line[1]?.right?.split(",")[1]
                                        }
                                        symbol="%"
                                    />
                                }
                            />
                            <MidRow
                                left={formatMessage(messages.propertyPredictedTotalReturn)}
                                right={
                                    <CustomFormatNumber
                                        value={
                                            actionBox?.line[2]?.right?.split(",")[0] +
                                            "." +
                                            actionBox?.line[2]?.right?.split(",")[1]
                                        }
                                        symbol="%"
                                    />
                                }
                            />
                            <MidRow left={formatMessage(messages.propertyDuration)} right={actionBox?.line[3]?.right} />
                        </>
                    ) : SECONDARY_MARKET_PROJECT_STATUS.includes(newStatus) ? (
                        <>
                            <MidRow
                                left={formatMessage(messages.spread)}
                                right={
                                    newStatus === PROJECT_STATUS.MARKETPLACE_PAUSED ? (
                                        <NoValue locale={locale} />
                                    ) : spread ? (
                                        <CustomFormatNumber value={spread} symbol="%" />
                                    ) : (
                                        <NoValue locale={locale} />
                                    )
                                }
                            />
                            <MidRow
                                left={formatMessage(messages.marketPriceVolatility)}
                                right={
                                    monthlyTMPVolatility ? (
                                        <CustomFormatNumber value={monthlyTMPVolatility} symbol="%" />
                                    ) : (
                                        <NoValue locale={locale} />
                                    )
                                }
                            />
                            <MidRow
                                left={formatMessage(messages.tokenMarketPrice)}
                                right={
                                    TMP ? <CustomFormatNumber value={TMP} symbol="€" /> : <NoValue locale={locale} symbol="€" />
                                }
                            />
                        </>
                    ) : (
                        <>
                            {isAssets ? (
                                <MidRow left={formatMessage(messages.numberOfInvestments)} right={numberOfInvestments} />
                            ) : null}
                            {!isAssets && price ? (
                                <MidRow
                                    left={formatMessage(messages.pricePerShare)}
                                    right={<CustomFormatNumber value={typeof price === "string" ? parseFloat(price) : price} />}
                                />
                            ) : isAssets ? (
                                <MidRow
                                    left={
                                        <>
                                            {formatMessage(messages.tokenPrice)}{" "}
                                            <span className="another-grey-color">- ITV {valuationDate}</span>
                                        </>
                                    }
                                    right={<PercentValueCom percentValue={percentValue} right={tokenPrice} />}
                                />
                            ) : null}
                            {shares ? (
                                <MidRow
                                    left={formatMessage(messages.numberDigitalParts)}
                                    // right={addGermanDecimal(shares)}
                                    right={<CustomFormatNumber value={shares} fixed={0} noSymbol />}
                                />
                            ) : null}
                            {digitalShares || digitalShares == 0 ? (
                                <MidRow
                                    left={formatMessage(messages.numberDigitalParts)}
                                    // right={addGermanDecimal(digitalShares)}
                                    right={
                                        <CustomFormatNumber
                                            value={digitalShares}
                                            fixed={0}
                                            symbol={digitalSharesSymbol}
                                            noSymbol={digitalSharesSymbol ? false : true}
                                        />
                                    }
                                />
                            ) : null}
                            {profit ? (
                                <MidRow
                                    left={formatMessage(messages.financingVolume)}
                                    right={<CustomFormatNumber value={profit} fixed={0} />}
                                />
                            ) : null}
                            {aggregate ? (
                                <MidRow
                                    left={formatMessage(messages.totalValue)}
                                    right={<CustomFormatNumber className="text-no-break" value={aggregate} fixed={2} />}
                                />
                            ) : null}
                            {isAssets ? (
                                <MidRow
                                    left={formatMessage(messages.indicativeWealth)}
                                    right={<CustomFormatNumber className="text-no-break" value={indicativeWealth} fixed={2} />}
                                />
                            ) : null}
                        </>
                    )
                }
            />
            {returnEstimate ? (
                <FooterDiv>
                    {newStatus === PROJECT_STATUS.SUCCESSFULLY_PAID_BACK ? (
                        <FooterPropertyRow
                            up={formatMessage(messages.propertyRealizedTotalReturn)}
                            down={actionBox?.line[0]?.right?.split(",")[0] + "." + actionBox?.line[0]?.right?.split(",")[1]}
                            symbol="%"
                        />
                    ) : SECONDARY_MARKET_PROJECT_STATUS.includes(newStatus) ? (
                        <FooterPropertyRow
                            up={<IntlFormattedMessage id={messages.indicativeTokenValue} />}
                            down={ITV || 0}
                            symbol="€"
                        />
                    ) : (
                        <FooterPropertyRow
                            isMultiple={isPrivateEquityProduct}
                            up={renderFooterNote(propertyType)}
                            down={isPrivateEquityProduct ? privateEquityReturn : returnEstimate}
                            symbol={isPrivateEquityProduct ? "x" : "%"}
                        />
                    )}
                </FooterDiv>
            ) : null}
        </PropertyItemProto>
    );
};
// const PropertyItemComponent = React.forwardRef(PropertyItem)
// export default PropertyItemComponent;

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(PropertyItem);
